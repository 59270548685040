<template>
    <div class="cart-item-card">
      <h3>Cart Total: ${{ cart_total.toFixed(2) }}</h3>
    </div>
</template>

<script>
export default {
  name: "CartSummaryPaymentCard",
  props: ['products'],
  computed: {
    cart_total() {
      return this.products.reduce((total, product) => {
        return total + product.price * product.quantity;
      }, 0);
    }
  },
}
</script>

<style >

</style>