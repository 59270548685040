<template>
  <div class="home">

    <div class="project-cards-container" v-if="items">
      <ProductSummaryCardAsync
        v-for="product in items"
        :key="product.id"
        :product="product"
        v-on:view-product="viewproduct($event)"
      />
    </div>
    <div v-if="product">
      <ProductDescriptionDrawerAsync
          :product="product"
          :active="active.product_drawer"
          v-on:close-product-drawer="closeProductDrawer()"
      />
    </div>

    <div class="side_checkout">
      <div class="checkout_body">
        <span class="counter">12</span>

        <p class="total">$ 145
          <span class="cart">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 512.000000 512.000000"
                 preserveAspectRatio="xMidYMid meet" width="20px" height="20px">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
               fill="#ffffff" stroke="none">
            <path d="M78 4789 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 37 -19 58
            -20 367 -20 l329 0 361 -1266 362 -1266 -29 -58 c-50 -99 -72 -177 -72 -260 0
            -208 135 -382 337 -435 43 -11 115 -15 265 -16 184 -1 200 -2 155 -13 -83 -20
            -146 -56 -211 -120 -177 -178 -177 -455 0 -631 179 -178 455 -178 631 -1 137
            138 171 338 88 514 -52 111 -188 218 -306 242 -24 4 262 8 658 8 573 0 691 -2
            650 -12 -83 -20 -146 -56 -211 -120 -177 -178 -177 -455 0 -631 179 -178 455
            -178 631 -1 178 179 178 454 0 632 -63 64 -127 100 -210 120 -43 11 -11 12
            238 13 268 1 291 2 327 21 45 23 80 80 80 130 0 50 -35 107 -80 130 -39 20
            -56 20 -1332 20 -1211 0 -1295 1 -1328 18 -81 40 -101 128 -53 227 l27 55
            1323 0 c1312 0 1324 0 1364 20 28 15 46 33 59 63 25 54 620 2138 620 2172 0
            45 -37 103 -80 125 -39 20 -55 20 -1953 20 l-1914 0 -67 238 c-80 282 -93 311
            -145 340 -38 22 -46 22 -442 22 -382 -1 -406 -2 -441 -21z"/>
            </g>
            </svg>
        </span>
        </p>

        <button class="checkout_button">Checkout</button>
      </div>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src
/*
import ProductSummaryCard from "@/components/products/ProductSummaryCard"
import ProductDescriptionDrawer from "@/components/products/ProductDescriptionDrawer"
*/
//import axios from "axios"
import { defineAsyncComponent } from "vue";
const ProductSummaryCardAsync = defineAsyncComponent(() =>
  import("@/components/products/ProductSummaryCard")
);
const ProductDescriptionDrawerAsync = defineAsyncComponent(() =>
  import("@/components/products/ProductDescriptionDrawer")
);

export default {
  components: {
    ProductSummaryCardAsync,
    ProductDescriptionDrawerAsync
  },
  data() {
    return {
      items: [],
      product: null,
      active: {
        product_drawer: false
      }
    }
  },
  methods: {
    viewproduct(product) {
      this.product = product;
      this.active.product_drawer = true;
      console.log(product);
    },
    closeProductDrawer() {
      this.active.product_drawer = false;
    }
  },
  mounted() {
    fetch("https://shop-backend-tarek.herokuapp.com/api/products")
        .then(response => response.json())
        .then(response => {
          console.log(response);
          this.items = response;
        }).catch(error => {
          console.log(error);
        });
  }
}
</script>

<style lang="scss" >
body {
  background-color: #1b1d21;
}
.home {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  padding: 0;
  margin: 0;
  height: 100vh;
}
  .project-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin-top: 50px;
    width: 85%;
  }

  .side_checkout {
    background-color: black;
    width: 15%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .checkout_body {
      display: block;
      margin-top: auto;
      margin-bottom: auto;

      .counter {
        color: white;
        font-size: 0.80rem;
        font-weight: bold;
        background-color: #d38312;
        border-radius: 50%;
        padding: 6px;
        line-height: 1;
        right: -10px;
        margin-left: 20px;
      }

      .total {
        color: white;
        font-size: 1.50rem;
        font-weight: bold;
        line-height: 1;
        margin: 15px;
      }

      .checkout_button {
        background: rgb(210,129,20);
        background: linear-gradient(139deg, rgba(210,129,20,1) 0%, rgba(168,51,120,1) 100%);
        color: white;
        font-size: 1.25rem;
        font-weight: lighter;
        padding: 10px 35px;
        border-radius: 2px;
        border: none;
        cursor: pointer;
        margin-top: -5px;
      }
      .cart {
        width: 20px;
        height: 20px;
      }
    }

  }

@media (max-width: 767px) {
  .project-cards-container {
    width: 60%;
  }
  .side_checkout {
    width: 40%;
  }
}

</style>
