import { createStore } from 'vuex'

function updateLocalStorage(state) {
  localStorage.setItem('cart', JSON.stringify(state))
}

export default createStore({
  state: {
    cart: []
  },
  getters: {
    productQuantity: state => product => {
      const item = state.cart.find(item => item.id === product.id)

      if (item) return item.quantity
      else return 0
    },

    cartItems : state => {
      return state.cart
    }
  },
  mutations: {
    addToCart (state, product) {
      let item = state.cart.find(i => i.id === product.id)

      if (item) {
        item.quantity++
      }
      else {
        state.cart.push({...product, quantity: 1})
      }

      updateLocalStorage(state.cart)
    },
    removeFromCart(state, product) {
      let item = state.cart.find(i => i.id === product.id)

      if (item) {
        if (item.quantity > 1) {
          item.quantity--
        }
        else {
          state.cart.splice(state.cart.indexOf(item), 1)
        }
      }

      updateLocalStorage(state.cart)
    },

    updateCartFromLocalStorage(state) {
      const cart = localStorage.getItem('cart')
      if (cart) {
        state.cart = JSON.parse(cart)
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
