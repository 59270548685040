<template>
  <div>
    <stripe-checkout
        ref="checkoutRef"
        :pk="publishableKey"
        :sessionId="sessionId"
    />
    <button class="view-product-button" @click="submit">Pay now!</button>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
//import axios from "axios";

export default {
  components: {
      StripeCheckout,
  },
  props: ['products'],
  data () {
    this.publishableKey = 'pk_test_51KuQedJYVnXhU2IIQ2m6ObTnguk4oryhcx4TIRal0gsMedKRhmdr66J7C5e2IzU9mPrUUnoJXhhVfMwu91abm9vV00gLDzXI3Z';
    return {
      sessionId: null,
    };
  },
  mounted() {

  },
  methods: {
    submit () {
      // You will be redirected to Stripe's secure checkout page
      if (this.cart_total > 0) {
        this.getSession()
      }
    },
    getSession() {
      fetch('https://shop-backend-tarek.herokuapp.com/api/get_session/'+this.cart_total+'00/USD/Product')
          .then(response => response.json())
        .then(response => {
          console.log(response);
          this.sessionId = response.id;
        }).then(res => {
          console.log(res);
          this.$refs.checkoutRef.redirectToCheckout();
        })
        .catch(error => {
          console.log(error.status);
        });
    }
  },
  computed: {
    cart_total() {
      return this.products.reduce((total, product) => {
        return total + product.price * product.quantity;
      }, 0);
    }
  }
};
</script>

