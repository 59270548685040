<template>
  <div>
    <h1>Your Cart</h1>

    <CartItemCard
        v-for="product in products"
        :key="product.id"
        :product="product"
    />
    <CartSummaryPaymentCard
    :products="products"
    />
    <StripeCheckout
        :products="products"
    />
  </div>
</template>

<script>
import CartItemCard from '@/components/cart/CartItemCard'
import CartSummaryPaymentCard from "@/components/cart/CartSummaryPaymentCard";
import StripeCheckout from "@/components/cart/StripeCheckout";

export default {
  components: {
    CartItemCard,
    CartSummaryPaymentCard,
    StripeCheckout
  },
  computed: {
    products() {
      return this.$store.getters.cartItems;
    }
  }
}
</script>