<template>
  <nav id="nav">
<!--
    <router-link :class="{active: $route.name == 'home'}" :to="{name : 'home'}">Home</router-link> |
-->
    <a class="{active: $route.name == 'home'}" href="/">Home</a> |
    <router-link :class="{active: $route.name == 'cart'}" :to="{name : 'cart'}">Cart</router-link>
  </nav>
  <router-view/>
</template>

<script>
export default {
  mounted() {
    this.$store.commit('updateCartFromLocalStorage')
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html, body {
  padding:0;
  margin:0;
  overflow-x: hidden;
}

body {
  background-color: rgb(245, 245, 245);
}

#nav {
  padding: 10px;
  margin: 10px;
  background-color: white;
  width: 100%;
  height: 30px;
  line-height: 30px;

  a {
    font-weight: bold;
    color: darkgray;
    text-decoration: none;
    margin: 0 5px 0;

    &.active {
      color: #2c3e50;
    }
  }
}

.text-center {
  text-align: center;
}

</style>
