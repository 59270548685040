<template>
  <div class="cart-item-card">
    <div class="header">
      <h3>{{ product.name }}</h3>
      <h4>In Cart: {{ product.quantity }}</h4>
      <h4>Total Cost: {{ item_cost.toFixed(2) }}</h4>
    </div>

    <p>{{ description }}</p>
    <div class="cart-footer">
      <button class="remove" @click="removeFromCart()">remove from Cart</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CartItemCard",
  props: ['product'],
  computed: {
    description() {
      return this.product.description.substring(0, 120) + '...';
    },
    item_cost: function() {
      return this.product.quantity * this.product.price;
    }
  },
  methods: {
    removeFromCart() {
      this.$store.commit('removeFromCart', this.product);
    }
  }
}
</script>

<style lang="scss">

.cart-item-card {
  width: 90%;
  margin: 5%;
  background-color:white;
  border-radius: 5px;
  box-shadow: 0 0 5px gray;
  padding: 10px;
  text-align: left;

  .header {
    display: flex;
    justify-content: space-around;
  }

  .cart-footer {
    display: flex;
    justify-content: flex-end;
  }

  button.remove {
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    float: right;
  }

}

</style>